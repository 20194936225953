<template>
    <div class="License page-container" v-loading="loading">
        <BreadcrumbBack title="License授权"/>
        <!-- 机器码 -->
        <div class="tit">机器码</div>
        <div class="tips">请将机器码复制给工作人员，获得授权文件</div>
        <el-row type="flex">
            <el-input class="mac" size="medium" readonly v-model="mac"/>
            <el-button size="small" class="copy" type="primary" @click="handleClickCopy" :data-clipboard-text="mac">复制</el-button>
        </el-row>
        <div class="tips">注：若节点扩容或更换了硬件设备，需要重新获取机器码，并上传授权文件</div>
        <!-- 授权文件 -->
        <div class="tit">授权文件</div>
        <el-row type="flex">
            <el-upload class="upload-lic"
                       ref="uploader"
                       action=""
                       :multiple="false" drag :limit="2"
                       accept=".lic"
                       :http-request="handleUpdLicese">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将license.lic文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
        </el-row>
        <div class="tips">注：只能为license.lic文件</div>
        <!-- 授权信息 -->
        <div class="tit">授权信息</div>
        <div class="license-info">
            <el-row type="flex">
                <div class="label">生效时间：</div>
                <div>{{ startTime || '-' }}</div>
            </el-row>
            <el-row type="flex">
                <div class="label">过期时间：</div>
                <div>{{ stopTime || '-' }}</div>
            </el-row>
            <el-row type="flex">
                <div class="label">剩余天数：</div>
                <div v-if="expired" class="expired">已过期</div>
                <div v-else class="effective">{{ expireDays || 0 }}{{ info }}</div>
            </el-row>
        </div>
    </div>
</template>

<script>
import Clipboard from 'clipboard'
import moment from 'moment'
import {mapActions, mapGetters, mapState} from 'vuex'
import BreadcrumbBack from "@/views/components/BreadcrumbBack";

export default {
    name: 'License',
    components: {
        BreadcrumbBack
    },
    data() {
        return {
            loading: false,
            mac: '',
        }
    },
    computed: {
        // ...mapState('app', ['loading', 'license', 'mac']),
        ...mapGetters(['license']),
        expired() {
            return this.license && this.license.expireFlag
        },
        expireDays() {
            return this.license && !this.expired ? `${this.license.expireDays < 1 ? '小于1' : this.license.expireDays}天` : ''
        },
        startTime() {
            return this.license && this.license.issuedTime ? moment(this.license.issuedTime).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        stopTime() {
            return this.license && this.license.expiryTime ? moment(this.license.expiryTime).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        info() {
            return this.license && this.license.msg ? ` (${this.license.msg})` : ''
        },
    },
    mounted() {
        this.getMac()
    },
    methods: {
        // ...mapActions('app', ['getMac', 'updLicense']),
        getMac () {
            this.$get('/license/getMachineCode')
            .then(res => {
                if (res.code === '200') {
                    this.mac = res.data
                }
            })
        },
        updLicense (file) {
            const formData = new FormData()
            formData.append('file', file)
            return new Promise(resolve => {
                this.$postJson('/license/upload', formData, {
                    headers: {
                        'Content-type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    if (res.code === '200') {
                        resolve()
                    }
                })
            })

        },

        handleClickCopy() {
            let clipboard = new Clipboard('.copy')
            clipboard.on('success', (e) => {
                clipboard.destroy()
                this.$message({message: '复制成功', type: 'success'})
            })
        },
        handleUpdLicese({file}) {
            this.$refs.uploader.uploadFiles.length > 1 && this.$refs.uploader.uploadFiles.splice(0, 1)
            this.updLicense(file).then(() => {
                this.$alert('授权成功，点击“知道了”将会刷新页面重新获取授权信息。', '授权提示', {
                    type: 'success',
                    confirmButtonText: '知道了'
                }).then(() => {
                    window.location.reload()
                })
            })
        },
    },
}
</script>

<style lang='scss' scoped>
.License {
    width: 100%;
    background-color: #fff;
    padding: 20px;

    .tit {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 20px;
        text-align: left;
    }

    .mac,
    .upload-lic,
    .license-info,
    .tips {
        width: 470px;
        margin-left: 32px;
    }

    .el-row + .el-row,
    .license-info {
        margin-top: 16px;
    }

    .tips {
        font-size: 14px;
        color: #8484af;
        margin-top: 4px;

        & + .tit {
            margin-top: 30px;
        }

        & + .el-row {
            margin-top: 8px;
        }
    }

    .copy {
        margin-left: 8px;
    }

    .upload-lic {
        /deep/ {
            .el-icon-close {
                display: none;
            }

            .el-upload,
            .el-upload-dragger {
                width: 100%;
            }
        }
    }

    .license-info {
        background-color: #f3f7fc;
        border-radius: 4px;
        padding: 24px 32px;
        font-size: 14px;

        .el-row {
            line-height: 2rem;
        }

        .label {
            color: #9aa0ba;
            width: 140px;

            + div {
                flex: 1;
            }
        }

        .expired {
            color: #ff4949;
        }

        .effective {
            color: #006bff;
        }
    }
}
</style>